import {
  useHistory,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_SETTINGS_INTEGRATIONS,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK,
} from 'router/routes.const';
import {SettingSubNav} from 'scenes/Settings/components/SubNav';
import './_styles.scss';

const TAB_CONNECTIONS = 'connections';
const TAB_WEBHOOK = 'webbhook';

export const Subnav = () => {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <SettingSubNav
      className="integrations-subnav"
      tabs={[
        {
          tabId: TAB_CONNECTIONS,
          content: 'Connections',
          active: match.path === ROUTE_SETTINGS_INTEGRATIONS,
        },
        {
          tabId: TAB_WEBHOOK,
          content: 'Webhook',
          active: match.path === ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK,
        },
      ]}
      onChangeTab={(tab) => {
        if (tab === TAB_WEBHOOK)
          return history.push(ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK);
        else return history.push(ROUTE_SETTINGS_INTEGRATIONS);
      }}
    />
  );
};
