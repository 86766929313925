import classNames from 'classnames';
import {BuilderContext} from 'contextes/builder';
import React, {useCallback, useContext, useState} from 'react';
import AnimateHeight from 'react-animate-height';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP,
  BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE,
  BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
} from 'services/steps';
import {BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS} from 'shared/front/components/Poke/constants/blocks';
import {getDefaultBlockFromType} from '../BlockManager/utils';
import {blocksObj} from '../Navigator';
import {MODE_ADD_BLOCK} from '../PokeBuilderSidebar';
import {getDefaultResourceCenterActionBlocks} from '../ResourceCenterBlockManager/utils';
import './_Styles.scss';
import NavigatorBlockItem from './components/NavigatorBlockItem';
import NavigatorItem from './components/NavigatorItem';
import {orderedBlockTypes, reorderList} from './utils';

const ResourceCenterNavigator = () => {
  const project = useSelector((state) => generalSelector.getProject(state));
  const {
    evolution,
    selectedStepId,
    selectedBlockType,
    setSelectedBlockType,
    selectedStep: step,
    updateStep,
    setMode,
    selectedSubItemId,
    setSelectedSubItemId,
  } = useContext(BuilderContext);

  const [currentDragDroppableId, setCurrentDragDroppableId] = useState(null);

  const onDragStart = useCallback((start) => {
    setCurrentDragDroppableId(start.source.droppableId);
  }, []);

  const deleteBlockAndChildren = useCallback(
    (blockId, isTriggerBlock = false) => {
      if (step?.blocks == null) {
        return;
      }

      const originalBlock = step.blocks.find((b) => b.uid === blockId);
      const originalParentId = originalBlock?.parentBlockId;

      const isDescendantOf = (block, ancestorId) => {
        if (!block.parentBlockId) return false;
        if (block.parentBlockId === ancestorId) return true;
        const parent = step.blocks.find((b) => b.uid === block.parentBlockId);
        return parent ? isDescendantOf(parent, ancestorId) : false;
      };

      const updatedBlocks = step.blocks.filter(
        (block) => block.uid !== blockId && !isDescendantOf(block, blockId)
      );

      updateStep(step.uid, {blocks: updatedBlocks});

      // Reset selection
      if (isTriggerBlock === true) {
        setSelectedBlockType(BLOCK_TYPE_RESOURCE_CENTER_TRIGGER);
        setSelectedSubItemId(null);
      } else {
        const newSelectedParent = originalParentId
          ? updatedBlocks.find((b) => b.uid === originalParentId)
          : null;

        if (newSelectedParent) {
          setSelectedSubItemId(newSelectedParent.uid);
          setSelectedBlockType(newSelectedParent.type);
        } else {
          setSelectedSubItemId(null);
          setSelectedBlockType(null);
        }
      }
    },
    [
      step?.blocks,
      updateStep,
      step?.uid,
      setSelectedBlockType,
      setSelectedSubItemId,
    ]
  );

  if (selectedStepId == null || !step) {
    return null;
  }

  const {blocks = []} = step;

  const handleAddActionToGroup = (parentBlockId) => {
    const existingBlock = blocks.find(
      (b) =>
        b.parentBlockId === parentBlockId &&
        b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION
    );
    const existingResourceItems = blocks.filter(
      (b) =>
        b.parentBlockId === parentBlockId &&
        b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION
    );

    const newBlock = {
      ...getDefaultBlockFromType(
        BLOCK_TYPE_RESOURCE_CENTER_ACTION,
        evolution.theme
      ),
      ...(existingBlock && {style: existingBlock.style}),
      parentBlockId,
      value: `Block #${existingResourceItems.length + 1}|-|${
        existingResourceItems.length
      }`,
    };

    const additionalBlocks = getDefaultResourceCenterActionBlocks(
      evolution,
      newBlock.uid
    );

    updateStep(step.uid, {blocks: [...blocks, newBlock, ...additionalBlocks]});
    setSelectedBlockType(BLOCK_TYPE_RESOURCE_CENTER_ACTION);
    setSelectedSubItemId(newBlock.uid);
  };

  // Filter blocks that are "header" ones (minimize, subtitle, title)
  const filteredLevel0Blocks = blocks.filter((b) =>
    [
      BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE,
      BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
      BLOCK_TYPE_RESOURCE_CENTER_TITLE,
    ].includes(b.type)
  );

  const triggerBlocks = blocks.filter((b) =>
    [
      BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
      BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
      BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
      BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
    ].includes(b.type)
  );

  // The main root blocks (actions or groups at root)
  const rootBlocks = blocks
    .filter((b) =>
      [
        BLOCK_TYPE_RESOURCE_CENTER_ACTION,
        BLOCK_TYPE_RESOURCE_CENTER_GROUP,
        BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
      ].includes(b.type)
    )
    .filter((b) => b.parentBlockId == null)
    .sort((a, b) => {
      const [, indexA] = a.value.split('|-|');
      const [, indexB] = b.value.split('|-|');
      return parseInt(indexA, 10) - parseInt(indexB, 10);
    });

  const isRootSelected =
    selectedBlockType == null ||
    [
      BLOCK_TYPE_RESOURCE_CENTER_MINIMIZE,
      BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
      BLOCK_TYPE_RESOURCE_CENTER_TITLE,
      BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
    ].includes(selectedBlockType);

  /**
   *  =========================
   *  Drag & Drop onDragEnd
   *  =========================
   */
  const onDragEnd = (result) => {
    const {source, destination} = result;

    // If user drops outside a droppable, reset position immediately
    if (!destination) {
      return;
    }

    // Strictly prevent cross-group dragging
    if (source.droppableId !== destination.droppableId) {
      return;
    }

    // The droppableId looks like "droppable-<parentId>"
    const parentId = source.droppableId.replace('droppable-', '');
    // If it's 'droppable-null' or 'droppable-root', handle that if you need to for top-level

    // Find the blocks with the same parent
    const siblings = blocks.filter(
      (b) => b.parentBlockId === (parentId === 'null' ? null : parentId)
    );

    // Reorder only the reorderable siblings: ACTION, GROUP
    const reorderableSiblings = siblings.filter((b) =>
      [
        BLOCK_TYPE_RESOURCE_CENTER_ACTION,
        BLOCK_TYPE_RESOURCE_CENTER_GROUP,
        BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
      ].includes(b.type)
    );

    // Sort them by the same logic we used in the UI
    reorderableSiblings.sort((a, b) => {
      const [, indexA] = a.value.split('|-|');
      const [, indexB] = b.value.split('|-|');
      return parseInt(indexA, 10) - parseInt(indexB, 10);
    });

    // Reorder that sub-array
    const newOrder = reorderList(
      reorderableSiblings,
      source.index,
      destination.index
    );

    // Now we have an updated array of reorderable siblings in the new order.
    // We need to put them back into `blocks` with updated indexes or updated positions.
    let updatedBlocks = [...blocks];

    // We can update the "value" of each block to reflect the new index, if needed
    newOrder.forEach((block, idx) => {
      const [label, _index, optionalBehavior, optionalChecklist] =
        block.value.split('|-|'); // e.g. "Block #1"
      // Rebuild the value with the new index
      const newValue = `${label}|-|${idx}${
        optionalBehavior ? `|-|${optionalBehavior}` : ''
      }${optionalChecklist ? `|-|${optionalChecklist}` : ''}`;
      block.value = newValue;
    });

    // Overwrite in updatedBlocks
    newOrder.forEach((reorderedBlock) => {
      const indexInAll = updatedBlocks.findIndex(
        (b) => b.uid === reorderedBlock.uid
      );
      if (indexInAll !== -1) {
        updatedBlocks[indexInAll] = {...reorderedBlock};
      }
    });

    // Finally, call updateStep with updated blocks
    updateStep(step.uid, {blocks: updatedBlocks});

    // Reset currentDragDroppableId
    setCurrentDragDroppableId(null);
  };

  const isEditingTrigger = [
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  ].includes(selectedBlockType);

  return (
    <div className="resource-center-navigator-wrapper">
      <div
        className={classNames('resource-center-navigator', {
          'is-dragging': currentDragDroppableId != null,
        })}>
        <div className="section-title">Elements</div>

        <div className="blocks-wrapper">
          {/* The top-level item for "Resource Center" */}
          <div
            className={classNames('blocks-title', {
              'is-open': isEditingTrigger !== true,
              activated: isRootSelected,
              selected: selectedBlockType == null,
            })}
            onClick={() => {
              setSelectedBlockType(null);
              setSelectedSubItemId(null);
            }}>
            <i className="isax isax-directbox-notif" />
            <div className="step-name">Resource Center</div>
          </div>

          <AnimateHeight
            height={isEditingTrigger !== true ? 'auto' : 0}
            duration={300}>
            <div className="blocks">
              {/* Level 0 blocks (Minimize, Subtitle, Title, etc.) */}
              <div
                className={classNames('blocks', {
                  expanded: isRootSelected,
                })}>
                {filteredLevel0Blocks
                  .sort(
                    (a, b) =>
                      orderedBlockTypes.indexOf(a.type) -
                      orderedBlockTypes.indexOf(b.type)
                  )
                  .map((block) => {
                    const {iconClassName, name} =
                      blocksObj.find((bObj) => bObj.type === block.type) || {};

                    const isSelectedBlock =
                      selectedBlockType === block.type &&
                      selectedSubItemId == null;

                    return (
                      <NavigatorItem
                        key={block.type}
                        type={block.type}
                        iconClassName={iconClassName}
                        name={name}
                        level={1}
                        className={classNames({
                          activated: isRootSelected,
                        })}
                        isSelected={isSelectedBlock}
                        deletable={[
                          BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
                          BLOCK_TYPE_RESOURCE_CENTER_TITLE,
                        ].includes(block.type)}
                        onSelect={(type) => {
                          setSelectedBlockType(type);
                          setSelectedSubItemId(null);
                        }}
                        onDelete={() => deleteBlockAndChildren(block.uid)}
                      />
                    );
                  })}

                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={onDragStart}>
                  {/* Root blocks (Actions / Groups at top level) */}
                  {/* We make a Droppable for the root-level siblings as well. */}
                  <Droppable
                    droppableId="droppable-null"
                    isDropDisabled={
                      currentDragDroppableId !== 'droppable-null'
                    }>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {rootBlocks.map((block, index) => (
                          <div
                            className={classNames('draggable-wrapper', {
                              selected: selectedSubItemId === block.uid,
                            })}>
                            <Draggable
                              key={block.uid}
                              draggableId={block.uid}
                              index={index}
                              isDragDisabled={
                                ![
                                  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
                                  BLOCK_TYPE_RESOURCE_CENTER_GROUP,
                                  BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
                                ].includes(block.type)
                              }>
                              {(draggableProvided) => (
                                <div
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  className={classNames({
                                    'same-droppable-group':
                                      currentDragDroppableId ===
                                      `droppable-null`,
                                  })}>
                                  <NavigatorBlockItem
                                    key={block.uid}
                                    block={block}
                                    blocks={blocks}
                                    level={1}
                                    selectedBlockType={selectedBlockType}
                                    selectedSubItemId={selectedSubItemId}
                                    setSelectedBlockType={setSelectedBlockType}
                                    setSelectedSubItemId={setSelectedSubItemId}
                                    setMode={setMode}
                                    updateStep={updateStep}
                                    step={step}
                                    evolution={evolution}
                                    handleAddActionToGroup={
                                      handleAddActionToGroup
                                    }
                                    deleteBlockAndChildren={
                                      deleteBlockAndChildren
                                    }
                                    className={classNames({
                                      activated: isRootSelected,
                                    })}
                                    dragHandleProps={
                                      draggableProvided.dragHandleProps
                                    }
                                    currentDragDroppableId={
                                      currentDragDroppableId
                                    }
                                  />
                                </div>
                              )}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              {/* "Add element" at the bottom of root */}
              <div
                className={classNames('add-block-wrapper subtitle-4 sw-1', {
                  detached: selectedSubItemId != null,
                })}
                onClick={() => {
                  if (selectedSubItemId != null) {
                    setSelectedBlockType(null);
                    setSelectedSubItemId(null);
                  }
                  setMode(MODE_ADD_BLOCK);
                }}>
                <div className="add-block-content">
                  <i className="icon-plus" />
                  Add element
                </div>
              </div>
            </div>
          </AnimateHeight>
        </div>

        {/* Trigger block */}
        <div
          className={classNames('blocks-wrapper', {
            'is-resource-center-trigger-open':
              selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
          })}>
          <div
            className={classNames('blocks-title', {
              'is-open': isEditingTrigger === true,
              activated: isEditingTrigger,
              selected:
                selectedBlockType === BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
            })}
            onClick={() => {
              setSelectedBlockType(BLOCK_TYPE_RESOURCE_CENTER_TRIGGER);
              setSelectedSubItemId(null);
            }}>
            <i className="isax isax-screenmirroring" />
            <div className="step-name">Trigger</div>
          </div>

          <AnimateHeight
            height={isEditingTrigger === true ? 'auto' : 0}
            duration={300}>
            <div className="blocks">
              <div
                className={classNames('blocks', {
                  expanded: isEditingTrigger,
                })}>
                {triggerBlocks
                  .sort(
                    (a, b) =>
                      orderedBlockTypes.indexOf(a.type) -
                      orderedBlockTypes.indexOf(b.type)
                  )
                  .map((block) => {
                    const {iconClassName, name} =
                      blocksObj.find((bObj) => bObj.type === block.type) || {};

                    const isSelectedBlock =
                      selectedBlockType === block.type &&
                      selectedSubItemId == null;

                    return (
                      <NavigatorItem
                        key={block.type}
                        type={block.type}
                        iconClassName={iconClassName}
                        iconWrapperClassName={classNames({
                          'progress-checklist':
                            block.type ===
                            BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
                        })}
                        name={name}
                        level={1}
                        className={classNames({
                          activated: isEditingTrigger,
                        })}
                        isSelected={isSelectedBlock}
                        deletable={
                          block.type !==
                          BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS
                        }
                        onSelect={(type) => {
                          setSelectedBlockType(type);
                          setSelectedSubItemId(null);
                        }}
                        onDelete={() => deleteBlockAndChildren(block.uid, true)}
                      />
                    );
                  })}
              </div>

              <div
                className={classNames('add-block-wrapper subtitle-4 sw-1', {})}
                onClick={() => {
                  setMode(MODE_ADD_BLOCK);
                }}>
                <div className="add-block-content">
                  <i className="icon-plus" />
                  Add element
                </div>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  );
};

export default ResourceCenterNavigator;
