import {default as classnames} from 'classnames';
import {BuilderContext} from 'contextes/builder';
import {GlobalContext} from 'contextes/Global';
import React, {useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {getDefaultChecklistSteps} from 'scenes/Pushes/components/ModalCreatePoke/utils';
import {getPreviewPoke} from 'scenes/Settings/scenes/Themes/utils';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_TYPE_CHECKLIST,
} from 'services/evolution';
import {
  BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
} from 'services/steps';
import ClickableBlockOverlay from 'shared/front/components/Poke/components/ClickableBlockOverlay';
import {PokeContext} from 'shared/front/components/Poke/context';
import ResourceCenter from 'shared/front/components/ResourceCenter';
import {
  RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_SELECTED_CHECKLIST,
  RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
} from 'shared/front/components/ResourceCenter/constants';
import JimoLabel from '../PokeRender/components/JimoLabel';
import {getResourceCenterPositionStyle} from '../PokeRender/utils';
import './_styles.scss';

const ResourceCenterRender = () => {
  const {addFontFamily} = useContext(GlobalContext);
  const {
    controlledEvolution: poke,
    setSelectedBlockType,
    selectedBlockType,
    selectedSubItemId,
    setSelectedSubItemId,
    selectedStep,
    selectedLanguage,
  } = useContext(BuilderContext);

  const project = useSelector((state) => generalSelector.getProject(state));

  const [isMinimized, setIsMinimized] = useState(false);

  const embeddedChecklistBlock = poke?.steps[0]?.blocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST
  );
  const [_name, _index, behavior, selectedChecklistId] =
    embeddedChecklistBlock?.value?.split('|-|') || [];
  const hasEmbeddedChecklist = embeddedChecklistBlock != null;

  const {data: checklist, isLoading} = useQuery({
    queryKey: ['checklist', selectedChecklistId],
    queryFn: async () => {
      if (!selectedChecklistId) {
        return null;
      }

      const isSelectedChecklist =
        behavior ===
        RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_SELECTED_CHECKLIST;

      if (isSelectedChecklist === true && selectedChecklistId != null) {
        return await evolutionService.getEvolutionById(selectedChecklistId, {
          relations: ['steps'],
        });
      } else {
        const checklists = await evolutionService.getEvolutions({
          type: EVOLUTION_TYPE_CHECKLIST,
          states: [EVOLUTION_STATE_LIVE],
          relations: ['steps'],
          removeDrafts: true,
          take: 1,
        });

        return checklists[0];
      }
    },
    enabled: hasEmbeddedChecklist,
  });

  const previewChecklist =
    hasEmbeddedChecklist === true && isLoading !== true
      ? checklist || {
          ...getPreviewPoke(),
          type: EVOLUTION_TYPE_CHECKLIST,
          steps: getDefaultChecklistSteps(),
        }
      : null;

  useEffect(() => {
    if (
      [
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
      ].includes(selectedBlockType)
    ) {
      setIsMinimized(true);
    } else {
      setIsMinimized(false);
    }
  }, [selectedBlockType]);

  const handleSelectedBlockType = (type) => {
    setSelectedBlockType(type);
  };

  const handleSelectedContainer = (containerId) => {
    setSelectedSubItemId(containerId);
  };

  const {
    style: {
      offsetX = 0,
      offsetY = 0,
      position = RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
    } = {},
    steps = [],
  } = poke ?? {};

  const stepIndex = steps.findIndex((step) => step.uid === selectedStep?.uid);

  const {style} = selectedStep;
  const {backgroundColor} = style ?? {};

  return (
    <PokeContext.Provider
      value={{
        poke: {
          ...poke,
          steps: poke?.steps?.sort((a, b) => a.inderOrder - b.indexOrder),
        },
        currentStepIndex: stepIndex,
        embeddedChecklist: previewChecklist,
      }}>
      <div
        className="resource-center-render-container"
        onClick={() => {
          setSelectedBlockType(null);
          setSelectedSubItemId(null);
        }}>
        {[
          BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
          BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
        ].includes(selectedBlockType) && (
          <div className="checklist-progress-warning">
            <i className="isax isax-eye-slash" />
            <div className="content">
              <div className="description body-4 n-800">
                Checklist elements only appear when user has an active
                checklist.
              </div>
            </div>
          </div>
        )}
        <div
          className={classnames('resource-center-wrapper', {
            selected: selectedBlockType == null,
          })}
          style={{
            ...getResourceCenterPositionStyle(position, offsetX, offsetY),
          }}>
          <div
            className={classnames('resource-center-render-wrapper', {
              selected: selectedBlockType == null,
            })}
            style={{
              height: 'auto',
            }}>
            <div
              className={classnames('resource-center-render')}
              style={{
                width: 'auto',
                height: 'auto',
                position: 'relative',
              }}>
              <ResourceCenter
                inBuilder
                JimoLabel={
                  project.whiteLabeling === false
                    ? () => <JimoLabel backgroundColor={backgroundColor} />
                    : undefined
                }
                onBlockSelected={handleSelectedBlockType}
                selectedBlock={selectedBlockType}
                onContainerSelected={handleSelectedContainer}
                selectedContainerId={selectedSubItemId}
                language={selectedLanguage}
                addFontFamily={addFontFamily}
                isMinimized={isMinimized}
                embeddedChecklist={previewChecklist}
              />
            </div>
            {isMinimized !== true && <ClickableBlockOverlay isNotCardElem />}
          </div>
        </div>
      </div>
    </PokeContext.Provider>
  );
};

export default ResourceCenterRender;
