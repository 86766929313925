import classNames from 'classnames';
import Button from 'components/Button';
import Divider from 'components/Divider';
import {BuilderContext} from 'contextes/builder';
import {func} from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_MEDIA,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
} from 'services/steps';
import {RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_ACTIVE_CHECKLIST} from 'shared/front/components/ResourceCenter/constants';
import {
  BLOCK_CATEGORY_BLOCKS,
  BLOCK_CATEGORY_INTERACTIONS,
  BLOCK_CATEGORY_MEDIA,
  BLOCK_CATEGORY_TEXT,
  BLOCKS,
  getDefaultBlockFromType,
} from '../BlockManager/utils';
import {ContentLoader} from '../ContentManager';
import {MODE_NAVIGATOR} from '../PokeBuilderSidebar';
import './_Styles.scss';
import {
  getDefaultResourceCenterActionBlocks,
  getDefaultResourceCenterGroupBlocks,
} from './utils';

const propTypes = {
  onClose: func,
};

const SectionItem = ({
  block,
  selected = false,
  disabled,
  onLoad = () => {},
  onError = () => {},
  ...rest
}) => {
  const {name, image} = block;
  return (
    <div
      className={classNames('section-item', {
        selected: selected,
        disabled: disabled,
      })}
      {...rest}>
      <div className="image-wrapper">
        <img src={image} alt="img" onLoad={onLoad} onError={onError} />
        <i className="icon-checkbox" />
        <i className="icon-plus-rounded" />
      </div>
      <div className="item-name">{name}</div>
    </div>
  );
};

const ResourceCenterBlockManager = ({onClose = () => {}}) => {
  const {
    evolution,
    selectedStep: step,
    updateStep,
    selectedBlockType,
    setSelectedBlockType,
    selectedSubItemId,
    setSelectedSubItemId,
    setMode,
  } = useContext(BuilderContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loadedCount, setLoadedCount] = useState(0);

  const handleImageLoad = () => {
    setLoadedCount((prev) => prev + 1);
  };

  const selectedRootBlock = step?.blocks.find(
    (b) => b.uid === selectedSubItemId
  );

  const isAddingToAction =
    selectedSubItemId != null &&
    selectedRootBlock?.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION;
  const isAddingToNestedAction =
    isAddingToAction === true && selectedRootBlock.parentBlockId != null;
  const isAddingToGroup =
    selectedSubItemId != null &&
    selectedRootBlock?.type === BLOCK_TYPE_RESOURCE_CENTER_GROUP;
  const isAddingToTrigger = [
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER,
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
    BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
  ].includes(selectedBlockType);
  const isAddingToResourceCenter =
    isAddingToAction === false &&
    isAddingToGroup === false &&
    isAddingToTrigger === false;

  const filteredBlocks = BLOCKS.filter((block) => {
    if (isAddingToAction === true) {
      return [
        BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
        BLOCK_TYPE_RESOURCE_CENTER_ACTION_PARAGRAPH,
        ...(isAddingToNestedAction === true
          ? []
          : [BLOCK_TYPE_RESOURCE_CENTER_ACTION_MEDIA]),
        BLOCK_TYPE_RESOURCE_CENTER_ACTION_ICON,
        BLOCK_TYPE_RESOURCE_CENTER_ACTION_CTA,
      ].includes(block.type);
    } else if (isAddingToGroup === true) {
      return [BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE].includes(block.type);
    } else if (isAddingToTrigger === true) {
      return [
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ICON,
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_TEXT,
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_ARROW,
        BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
      ].includes(block.type);
    } else {
      return [
        BLOCK_TYPE_RESOURCE_CENTER_TITLE,
        BLOCK_TYPE_RESOURCE_CENTER_SUBTITLE,
        BLOCK_TYPE_RESOURCE_CENTER_ACTION,
        BLOCK_TYPE_RESOURCE_CENTER_GROUP,
        BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
      ].includes(block.type);
    }
  });

  const imagesLength = filteredBlocks.length;

  useEffect(() => {
    if (loadedCount === imagesLength) {
      setIsLoading(false);
    }
  }, [loadedCount, imagesLength]);

  if (step == null) {
    return <></>;
  }

  const {blocks = []} = step;

  const addBlock = (type) => {
    const existingBlock = blocks.find((block) => block.type === type);

    const existingResourceItemBlocks = blocks.filter(
      (b) =>
        [
          BLOCK_TYPE_RESOURCE_CENTER_ACTION,
          BLOCK_TYPE_RESOURCE_CENTER_GROUP,
          BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
        ].includes(b.type) && b.parentBlockId === selectedSubItemId
    );

    const extraValueData =
      type === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST
        ? `|-|${RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_ACTIVE_CHECKLIST}|-|`
        : null;

    const newBlock = {
      ...getDefaultBlockFromType(type, evolution.theme),
      ...(existingBlock && {
        style: existingBlock.style,
      }),
      parentBlockId:
        isAddingToAction === true || isAddingToGroup === true
          ? selectedSubItemId
          : null,
      ...([
        BLOCK_TYPE_RESOURCE_CENTER_ACTION,
        BLOCK_TYPE_RESOURCE_CENTER_GROUP,
        BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
      ].includes(type) && {
        value: `Block #${existingResourceItemBlocks.length + 1}|-|${
          existingResourceItemBlocks.length
        }${extraValueData}`,
      }),
    };

    let additionalBlocks = [];

    if (type === BLOCK_TYPE_RESOURCE_CENTER_GROUP) {
      additionalBlocks = getDefaultResourceCenterGroupBlocks(
        evolution,
        newBlock.uid
      );
    } else if (type === BLOCK_TYPE_RESOURCE_CENTER_ACTION) {
      additionalBlocks = getDefaultResourceCenterActionBlocks(
        evolution,
        newBlock.uid
      );
    }

    updateStep(step.uid, {
      blocks: [...(step?.blocks || []), newBlock, ...additionalBlocks],
    });

    setSelectedBlockType(type);
    if (
      [
        BLOCK_TYPE_RESOURCE_CENTER_ACTION,
        BLOCK_TYPE_RESOURCE_CENTER_GROUP,
      ].includes(type)
    ) {
      setSelectedSubItemId(newBlock.uid);
    }
    onClose();
  };

  const removeBlock = (type) => {
    updateStep(step.uid, {
      blocks: step?.blocks.filter((block) => {
        if (type === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST) {
          return (
            [
              BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
              BLOCK_TYPE_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
            ].includes(block.type) === false
          );
        }
        return (
          block.type !== type ||
          (selectedSubItemId == null
            ? block.parentBlockId != null
            : block.parentBlockId !== selectedSubItemId)
        );
      }),
    });
    if (selectedBlockType === type) {
      setSelectedBlockType(null);
    }
  };

  const textBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_TEXT
  );
  const blocksBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_BLOCKS
  );
  const mediaBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_MEDIA
  );
  const interactionsBlocks = filteredBlocks.filter(
    (block) => block.category === BLOCK_CATEGORY_INTERACTIONS
  );

  const activeBlocks = blocks.filter((block) =>
    selectedSubItemId == null
      ? block.parentBlockId == null
      : block.parentBlockId === selectedSubItemId
  );

  const renderItem = ({block, index, isActive, isDisabled}) => {
    return (
      <SectionItem
        key={index}
        block={block}
        selected={isActive}
        disabled={isDisabled}
        onClick={() => {
          if (isDisabled) {
            return;
          }

          isActive ? removeBlock(block.type) : addBlock(block.type);
        }}
        onLoad={handleImageLoad}
        onError={handleImageLoad}
      />
    );
  };

  return (
    <div className="resource-center-block-manager-wrapper">
      <div className="block-manager">
        <div className="block-manager-header">
          <Button
            className="back-btn"
            iconOnly
            iconLeft="icon-chevron-left"
            onClick={() => setMode(MODE_NAVIGATOR)}
          />
          <div className="block-manager-title">
            Add
            {isAddingToResourceCenter && (
              <>
                {' '}
                to{' '}
                <div className="icon-wrapper resource-center">
                  <i className="isax isax-home" />
                </div>{' '}
                Resource Center
              </>
            )}
            {isAddingToAction && (
              <>
                {' '}
                to{' '}
                <div className="icon-wrapper action">
                  <i className="isax isax-tick-square" />
                </div>{' '}
                {selectedRootBlock?.value?.split('|-|')[0]}
              </>
            )}
            {isAddingToGroup && (
              <>
                {' '}
                to{' '}
                <div className="icon-wrapper group">
                  <i className="isax isax-tick-square" />
                </div>{' '}
                {selectedRootBlock?.value?.split('|-|')[0]}
              </>
            )}
            {isAddingToTrigger && (
              <>
                {' '}
                to{' '}
                <div className="icon-wrapper add-to-trigger">
                  <i className="isax isax-screenmirroring" />
                </div>{' '}
                Trigger
              </>
            )}
          </div>
          <i className="icon-close" onClick={onClose} />
        </div>
        <Divider />
        {isLoading === true && <ContentLoader />}
        <div
          className={classNames('sections', {
            'is-loading': isLoading,
          })}>
          {isAddingToResourceCenter && (
            <>
              <div className="section">
                <div className="section-title">Text</div>
                <div className="section-items">
                  {textBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );

                    return renderItem({block, index, isActive});
                  })}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title">Blocks</div>
                <div className="section-items">
                  {blocksBlocks.map((block, index) => {
                    const isActive =
                      block.type ===
                        BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST &&
                      activeBlocks.find((b) => b.type === block.type);
                    const isDisabled =
                      block.type ===
                        BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST &&
                      activeBlocks.find((b) => b.type === block.type);

                    return renderItem({block, index, isActive, isDisabled});
                  })}
                </div>
              </div>
            </>
          )}

          {isAddingToAction && (
            <>
              <div className="section">
                <div className="section-title">Text</div>
                <div className="section-items">
                  {textBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );

                    return renderItem({block, index, isActive});
                  })}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title">Media assets</div>
                <div className="section-items">
                  {mediaBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );

                    return renderItem({block, index, isActive});
                  })}
                </div>
              </div>
              <Divider />
              <div className="section">
                <div className="section-title">Interactive</div>
                <div className="section-items">
                  {interactionsBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );

                    return renderItem({block, index, isActive});
                  })}
                </div>
              </div>
            </>
          )}

          {isAddingToGroup && (
            <>
              <div className="section">
                <div className="section-items">
                  {textBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );
                    return renderItem({block, index, isActive});
                  })}
                </div>
              </div>
            </>
          )}

          {isAddingToTrigger && (
            <>
              <div className="section">
                <div className="section-title">Text</div>
                <div className="section-items">
                  {filteredBlocks.map((block, index) => {
                    const isActive = activeBlocks.find(
                      (b) => b.type === block.type
                    );
                    return renderItem({block, index, isActive});
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ResourceCenterBlockManager.propTypes = propTypes;

export default ResourceCenterBlockManager;
