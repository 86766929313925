import * as analytic from './analytic';
import * as auth from './auth';
import * as buildUrl from './build-url';
import * as comment from './comment';
import * as coupon from './coupon';
import * as crispWidget from './crisp-widget';
import * as customAttribute from './custom-attribute';
import * as customDomain from './custom-domain';
import * as customerio from './customerio';
import * as dataImport from './data-import';
import * as environment from './environment';
import * as event from './event';
import * as eventProperty from './event-property';
import * as evolution from './evolution';
import * as evolutionAnalytics from './evolution-analytics';
import * as evolutionListView from './evolution-list-view';
import * as evolutionSlackChannels from './evolution-slack-channels';
import * as feedback from './feedback';
import * as file from './file';
import * as fileViewer from './file-viewer';
import * as gtm from './gtm';
import * as hubspot from './hubspot';
import * as intercom from './intercom';
import * as issue from './issue';
import * as jimer from './jimer';
import * as jimerAnalytics from './jimer-analytics';
import * as me from './me';
import * as mixpanel from './mixpanel';
import * as openai from './openai';
import * as posthog from './posthog';
import * as project from './project';
import * as prototype from './prototype';
import * as request from './request';
import * as roles from './roles';
import * as section from './section';
import * as segment from './segment';
import * as segmentAnalytics from './segment-analytics';
import * as segmentio from './segmentio';
import * as slack from './slack';
import * as sse from './sse';
import * as steps from './steps';
import * as subscription from './subscription';
import * as survey from './survey';
import * as tag from './tag';
import * as theme from './theme';
import * as tracker from './tracker';
import * as trackerAnalytics from './tracker-analytics';
import * as typeform from './typeform';
import * as webhook from './webhooks';
import * as zapier from './zapier';

export const meService = me;
export const authService = auth;
export const projectService = project;
export const requestService = request;
export const evolutionService = evolution;
export const commentService = comment;
export const prototypeService = prototype;
export const fileViewerService = fileViewer;
export const tagService = tag;
export const subscriptionService = subscription;
export const fileService = file;
export const couponService = coupon;
export const customDomainService = customDomain;
export const jimerService = jimer;
export const segmentService = segment;
export const slackService = slack;
export const analyticService = analytic;
export const typeformService = typeform;
export const stepsService = steps;
export const intercomService = intercom;
export const segmentioService = segmentio;
export const segmentAnalyticsService = segmentAnalytics;
export const evolutionAnalyticsService = evolutionAnalytics;
export const jimerAnalyticsService = jimerAnalytics;
export const eventService = event;
export const buildUrlService = buildUrl;
export const sectionService = section;
export const customAttributeService = customAttribute;
export const themeService = theme;
export const surveyService = survey;
export const gtmService = gtm;
export const zapierService = zapier;
export const openaiService = openai;
export const evolutionListViewService = evolutionListView;
export const issueService = issue;
export const rolesService = roles;
export const trackerService = tracker;
export const trackerAnalyticsService = trackerAnalytics;
export const customerioService = customerio;
export const hubspotService = hubspot;
export const dataImportService = dataImport;
export const evolutionSlackChannelsService = evolutionSlackChannels;
export const feedbackService = feedback;
export const environmentService = environment;
export const crispWidgetService = crispWidget;
export const posthogService = posthog;
export const mixpanelService = mixpanel;
export const sseService = sse;
export const eventPropertyService = eventProperty;
export const webhookService = webhook;
