import {Icon} from '@iconify/react';
import classNames from 'classnames';
import React, {useContext} from 'react';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';
import {BLOCK_RESOURCE_CENTER_ACTION_ICON} from '../../../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../../../Poke/context';
import {
  RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR,
  RESOURCE_CENTER_ICON_SOURCE_TWEMOJI,
} from '../../../../constants';
import './_Styles.scss';

const ResourceCenterActionIcon = ({block}) => {
  const {
    onBlockSelected,
    onContainerSelected,
    selectedBlock,
    inBuilder,
    selectedContainerId,
  } = useContext(PokeStateContext);

  const {style} = block;
  const {
    fontSize,
    iconColor,
    backgroundColor,
    borderColor,
    borderRadius,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    iconSource,
    iconName,
  } = style;

  const icon =
    iconSource === RESOURCE_CENTER_ICON_SOURCE_PHOSPHOR ? (
      <Icon icon={`ph:${iconName}`} />
    ) : iconSource === RESOURCE_CENTER_ICON_SOURCE_TWEMOJI ? (
      <Icon icon={`twemoji:${iconName}`} />
    ) : null;

  return (
    <div
      className={classNames('block-resource-center-action-icon', {
        'poke-block-clickable': inBuilder === true,
        selected:
          selectedBlock === BLOCK_RESOURCE_CENTER_ACTION_ICON &&
          selectedContainerId === block?.parentBlockId,
        'in-builder': inBuilder === true,
      })}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_RESOURCE_CENTER_ACTION_ICON);
          onContainerSelected(block.parentBlockId);
        }
      }}
      style={{
        backgroundColor,
        ...(borderColor != null ? {border: `1px solid ${borderColor}`} : {}),
        borderRadius,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }}>
      <div
        className="icon"
        style={{
          height: fontSize,
          width: fontSize,
          color: iconColor,
        }}>
        {icon}
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterActionIcon;
