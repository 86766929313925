import Alert from 'components/Alert';
import Divider from 'components/Divider';
import SelectGroup from 'components/Select';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import React, {useContext} from 'react';
import {useQuery} from 'react-query';
import Section from 'scenes/PokeBuilder/components/Section';
import SectionItem from 'scenes/PokeBuilder/components/SectionItem';
import {evolutionService} from 'services';
import {
  EVOLUTION_STATE_LIVE,
  EVOLUTION_TYPE_CHECKLIST,
} from 'services/evolution';
import {BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST} from 'services/steps';
import {
  RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_ACTIVE_CHECKLIST,
  RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_SELECTED_CHECKLIST,
} from 'shared/front/components/ResourceCenter/constants';
import {Swaler} from 'swaler';
import './_Styles.scss';

const behaviorOptions = [
  {
    label: (
      <>
        <span className="body-3 n-800">Active checklist</span>
        <span className="body-4 n-700">
          Automatically display a user’s current active checklist in the
          element.
        </span>
      </>
    ),
    value: RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_ACTIVE_CHECKLIST,
  },
  {
    label: (
      <>
        <span className="body-3 n-800">Selected checklist</span>
        <span className="body-4 n-700">
          Choose any checklist to display, even if it’s already completed by the
          user.
        </span>
      </>
    ),
    value: RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_SELECTED_CHECKLIST,
  },
];

const logger = new Swaler('ResourceCenterEmbeddedChecklist');

const ResourceCenterEmbeddedChecklist = () => {
  const {updateBlock: uptBlock, selectedStep: step} =
    useContext(BuilderContext);

  const {data: checklists = [], isLoading} = useQuery({
    queryKey: 'checklists',
    queryFn: async () => {
      try {
        const experiences = await evolutionService.getEvolutions({
          type: EVOLUTION_TYPE_CHECKLIST,
          states: [EVOLUTION_STATE_LIVE],
          removeDrafts: true,
        });

        return experiences;
      } catch (err) {
        const {code, title, message, actions} = errorHelpers.parseError(err);

        logger.error('Fetch experiences failed with error ', code);
        toastDanger([title, message], {actions});
      }
    },
  });

  const checklistsOptions = checklists?.map((e) => ({
    label: e.title,
    value: e.uid,
    evolution: e,
  }));

  const updateBlock = (updateObj) => {
    uptBlock(BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST, updateObj);
  };

  const block = step?.blocks.find(
    (b) => b.type === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST
  );

  if (block == null) {
    return <></>;
  }

  const {value} = block;
  const [name, index, behavior, checklist] = value.split('|-|');

  const stylingAlert = (
    <Alert
      success
      icon="isax isax-tick-circle5"
      title="The checklist style will match the other elements.">
      This checklist will automatically follow the style of other elements for a
      consistent look.
    </Alert>
  );

  return (
    <div className="block-settings resource-center-embedded-checklist">
      <Section title="Behavior">
        <SectionItem title="Show content">
          <SelectGroup
            isSearchable={false}
            classNamePrefix="behavior-embedded-checklist-select"
            options={behaviorOptions}
            value={behaviorOptions.find((o) => o.value === behavior)}
            onChange={(option) => {
              updateBlock({
                value: `${name}|-|${index}|-|${option.value}|-|${checklist}`,
              });
            }}
            menuPortalTarget={document.body}
          />
        </SectionItem>
        {behavior !==
          RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_SELECTED_CHECKLIST &&
          stylingAlert}
      </Section>
      <Divider />
      {behavior ===
        RESOURCE_CENTER_EMBEDDED_CHECKLIST_BEHAVIOR_SELECTED_CHECKLIST && (
        <Section title="Checklist">
          <SectionItem title="Checklist">
            <SelectGroup
              isLoading={isLoading}
              options={checklistsOptions}
              value={checklistsOptions.find((o) => o.value === checklist)}
              onChange={(option) => {
                updateBlock({
                  value: `${name}|-|${index}|-|${behavior}|-|${option.value}`,
                });
              }}
            />
          </SectionItem>
          {stylingAlert}
        </Section>
      )}
    </div>
  );
};

export default ResourceCenterEmbeddedChecklist;
