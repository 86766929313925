import classNames from 'classnames';
import React, {useContext} from 'react';
import {BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS} from '../../../../../Poke/constants/blocks';
import {PokeStateContext} from '../../../../../Poke/context';
import {
  RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_COUNT,
  RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_PERCENTAGE,
} from '../../../../constants';
import './_Styles.scss';
import ClickableBlockOverlay from '../../../../../Poke/components/ClickableBlockOverlay';

const ResourceCenterTriggerChecklistProgress = ({
  innerRef,
  paddingTop,
  paddingBottom,
  gap,
}) => {
  const {
    onBlockSelected,
    onContainerSelected,
    selectedBlock,
    inBuilder,
    blocks,
  } = useContext(PokeStateContext);

  const block = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS
  );

  if (block == null) {
    return <></>;
  }

  const {style} = block;
  const {type, color} = style;

  return (
    <div
      ref={innerRef}
      className={classNames(
        'block-resource-center-trigger-checklist-progress',
        {
          'poke-block-clickable': inBuilder === true,
          selected:
            selectedBlock === BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
          'in-builder': inBuilder === true,
        }
      )}
      onClick={(e) => {
        if (inBuilder === true) {
          e.stopPropagation();
          onBlockSelected(BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS);
          onContainerSelected(null);
        }
      }}
      style={{
        color,
        paddingTop,
        paddingBottom,
        gap,
      }}>
      <div
        className="divider"
        style={{
          backgroundColor: color,
        }}
      />
      <div className="checklist-progress-content">
        <i className="icon-tick" />
        {type === RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_COUNT && (
          <div className="count-progress">1/5</div>
        )}
        {type ===
          RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS_TYPE_PERCENTAGE && (
          <div className="percentage-progress">20%</div>
        )}
      </div>
      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterTriggerChecklistProgress;
