import classNames from 'classnames';
import {hasFlag} from '../../helpers/bitwise';
import React, {useContext, useEffect, useRef} from 'react';
import {F_STEP_HEIGHT_CUSTOM} from '../Poke';
import {
  BLOCK_CHECKLIST_TRIGGER,
  BLOCK_RESOURCE_CENTER_TRIGGER,
} from '../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../Poke/context';
import './_Styles.scss';
import ResourceCenterLayout from './components/ResourceCenterLayout';
import ResourceCenterTrigger from './components/ResourceCenterTrigger';

const ResourceCenter = ({
  inBuilder = false,
  selectedBlock = null,
  selectedContainerId = null,
  JimoLabel = null,
  isMinimized = false,
  completedTasks = [],
  language = null,
  disableAnimations = false,

  addFontFamily = () => {},
  onBlockSelected = () => {},
  onContainerSelected = () => {},
  setIsMinimized = () => {},
  onDimensionChange = () => {},
  onClose = () => {},
  onTaskCompleted = () => {},
  onTaskClicked = () => {},
  onTaskSkipped = () => {},
  onTriggerActions = () => {},
  onCtaClick = () => {},
}) => {
  const {poke} = useContext(PokeContext);

  const steps = poke?.steps ?? [];
  const step = steps[0];
  const {blocks = []} = step;

  const resourceCenterRef = useRef();

  const resourceCenterHeight = resourceCenterRef?.current?.clientHeight;
  const resourceCenterWidth = resourceCenterRef?.current?.clientWidth;

  useEffect(() => {
    if (inBuilder !== true) {
      return;
    }

    if (selectedBlock === BLOCK_CHECKLIST_TRIGGER) {
      setIsMinimized(true);
    } else {
      setIsMinimized(false);
    }
  }, [selectedBlock]);

  useEffect(() => {
    if (!resourceCenterHeight || !resourceCenterWidth) {
      return;
    }
    onDimensionChange([resourceCenterWidth, resourceCenterHeight]);
  }, [resourceCenterHeight, resourceCenterWidth]);

  const triggerBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER
  );

  if (triggerBlock == null) {
    return null;
  }

  const {style: pokeStyle} = poke;
  const {style} = step;

  const {backgroundColor, shadow, borderRadius, borderColor} = pokeStyle ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = color != null ? `${x}px ${y}px ${blur}px ${color}` : 'none';

  const {width, height} = style ?? {};
  const hasCustomHeight = hasFlag(F_STEP_HEIGHT_CUSTOM, step?.stepFlags);

  const resourceCenterStyle = {
    ...(isMinimized === false
      ? width != null
        ? {width: `${width}px`}
        : {}
      : {}),
    ...(hasCustomHeight === true
      ? height != null
        ? {maxHeight: `${height}px`}
        : {}
      : {}),
    backgroundColor,
    boxShadow: boxShadow,
    borderRadius: `${borderRadius}px`,
    ...(borderColor != null ? {outline: `1px solid ${borderColor}`} : {}),
  };

  return (
    <PokeStateContext.Provider
      value={{
        addFontFamily,
        inBuilder,
        step,
        onBlockSelected,
        onContainerSelected,
        selectedContainerId,
        selectedBlock,
        blocks,
        JimoLabel,
        isMinimized,
        setIsMinimized,
        completedTasks,
        language,
        close: onClose,
        onTaskCompleted,
        onTaskClicked,
        onTaskSkipped,
        onTriggerActions,
        onCtaClick, // add onCtaClick as it is used in success step cta
      }}>
      <div className="block-resource-center-container">
        <div
          ref={resourceCenterRef}
          className={classNames('block-resource-center', {
            'is-mini': isMinimized === true,
            'disable-animations': disableAnimations === true,
          })}
          onClick={() => {
            onBlockSelected(null);
            onContainerSelected(null);
          }}
          style={{
            ...resourceCenterStyle,
          }}>
          <ResourceCenterLayout />
        </div>

        <ResourceCenterTrigger />
      </div>
    </PokeStateContext.Provider>
  );
};

export default ResourceCenter;
