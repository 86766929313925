import classNames from 'classnames';
import React, {useContext, useRef, useState} from 'react';
import ClickableBlockOverlay from '../../../Poke/components/ClickableBlockOverlay';
import {
  BLOCK_RESOURCE_CENTER_TRIGGER,
  BLOCK_RESOURCE_CENTER_TRIGGER_ARROW,
  BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS,
  BLOCK_RESOURCE_CENTER_TRIGGER_ICON,
  BLOCK_RESOURCE_CENTER_TRIGGER_TEXT,
} from '../../../Poke/constants/blocks';
import {PokeContext, PokeStateContext} from '../../../Poke/context';
import {
  RESOURCE_CENTER_TRIGGER_POSITION_LEFT,
  RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
  RESOURCE_CENTER_TRIGGER_TYPE_COMPACT,
} from '../../constants';
import './_Styles.scss';
import ResourceCenterTriggerArrow from './components/ResourceCenterTriggerArrow';
import ResourceCenterTriggerChecklistProgress from './components/ResourceCenterTriggerChecklistProgress';
import ResourceCenterTriggerIcon from './components/ResourceCenterTriggerIcon';
import ResourceCenterTriggerText from './components/ResourceCenterTriggerText';
import {useElementWidth} from './hooks/UseElementWidth';

const ResourceCenterTrigger = () => {
  const {poke} = useContext(PokeContext);
  const {
    blocks,
    inBuilder,
    onBlockSelected,
    onContainerSelected,
    isMinimized,
    setIsMinimized,
    selectedBlock,
  } = useContext(PokeStateContext);

  const triggerBlock = blocks.find(
    (b) => b.type === BLOCK_RESOURCE_CENTER_TRIGGER
  );

  const {style: {position = RESOURCE_CENTER_TRIGGER_POSITION_RIGHT} = {}} =
    poke ?? {};
  const {style} = triggerBlock ?? {};
  const {
    gap,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    borderRadius,
    backgroundColor,
    borderColor,
    shadow,
  } = style ?? {};

  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;

  const [isHovered, setIsHovered] = useState(false);

  const checklistProgressRef = useRef(null);
  const triggerHiddenContentContainerRef = useRef(null);
  const triggerIconWrapperRef = useRef(null);

  const triggerIconWrapperWidth = useElementWidth(triggerIconWrapperRef);
  const triggerHiddenContentContainerWidth = useElementWidth(
    triggerHiddenContentContainerRef
  );
  const checklistProgressWidth = useElementWidth(checklistProgressRef);

  const isCompact =
    style?.type === RESOURCE_CENTER_TRIGGER_TYPE_COMPACT &&
    [
      BLOCK_RESOURCE_CENTER_TRIGGER_ICON,
      BLOCK_RESOURCE_CENTER_TRIGGER_TEXT,
      BLOCK_RESOURCE_CENTER_TRIGGER_ARROW,
    ].includes(selectedBlock) !== true;

  const hasChecklistProgress =
    selectedBlock === BLOCK_RESOURCE_CENTER_TRIGGER_CHECKLIST_PROGRESS;

  return (
    <div
      className={classNames('block-resource-center-trigger', {
        'is-left': position === RESOURCE_CENTER_TRIGGER_POSITION_LEFT,
        'is-right': position === RESOURCE_CENTER_TRIGGER_POSITION_RIGHT,
        'is-mini': isMinimized === true,
        'is-compact': isCompact,
        'has-checklist-progress': hasChecklistProgress,
      })}
      style={{
        paddingLeft,
        paddingRight,
        borderRadius,
        backgroundColor,
        ...(borderColor != null && {border: `1px solid ${borderColor}`}),
        boxShadow,

        width: triggerIconWrapperWidth + triggerHiddenContentContainerWidth,
        ...(isCompact === true &&
          isHovered === false && {
            width:
              triggerIconWrapperWidth +
              (hasChecklistProgress === true ? checklistProgressWidth : 0),
          }),
      }}
      onClick={(e) => {
        e.stopPropagation();

        if (inBuilder === true) {
          onBlockSelected(BLOCK_RESOURCE_CENTER_TRIGGER);
          onContainerSelected(null);
          return;
        }

        setIsMinimized(!isMinimized);
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}>
      <div
        className="block-resource-center-trigger-content"
        style={{
          gap,
        }}>
        <div
          ref={triggerIconWrapperRef}
          className="block-resource-center-trigger-icon-wrapper"
          style={{
            paddingTop,
            paddingBottom,
          }}>
          <ResourceCenterTriggerIcon />
        </div>
        <div className="trigger-hidden-content-container-wrapper">
          <div
            ref={triggerHiddenContentContainerRef}
            className="trigger-hidden-content-container"
            style={{
              gap,
              paddingTop,
              paddingBottom,
            }}>
            <ResourceCenterTriggerText />
            <ResourceCenterTriggerArrow />
          </div>
        </div>
        <ResourceCenterTriggerChecklistProgress
          innerRef={checklistProgressRef}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
          gap={gap}
        />
      </div>

      <ClickableBlockOverlay />
    </div>
  );
};

export default ResourceCenterTrigger;
