import classNames from 'classnames';
import {htmlToText} from 'html-to-text';
import AnimateHeight from 'react-animate-height';
import {Draggable, Droppable} from 'react-beautiful-dnd';
import {blocksObj} from 'scenes/PokeBuilder/components/Navigator';
import {MODE_ADD_BLOCK} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import {
  BLOCK_TYPE_RESOURCE_CENTER_ACTION,
  BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE,
  BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP,
  BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE,
} from 'services/steps';
import {
  getBlockIcon,
  isBlockOrAncestorOfSelected,
  orderedBlockTypes,
} from '../../utils';
import NavigatorItem from '../NavigatorItem';
import './_Styles.scss';

/**
 * Renders a block (and optionally its children) in the navigator.
 * Handles expand/collapse logic, nesting, and the "Add element/action" at the bottom,
 * plus the Droppable/Draggable logic for reorderable children.
 */
const NavigatorBlockItem = ({
  block,
  blocks,
  level,
  selectedBlockType,
  selectedSubItemId,
  setSelectedBlockType,
  setSelectedSubItemId,
  setMode,
  updateStep,
  step,
  evolution,
  handleAddActionToGroup,
  deleteBlockAndChildren,
  className,
  dragHandleProps,
  currentDragDroppableId,
}) => {
  const {uid, type} = block;

  let name = '';
  let titleBlock = null;
  if (type === BLOCK_TYPE_RESOURCE_CENTER_GROUP) {
    titleBlock = blocks.find(
      (b) =>
        b.type === BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE &&
        b.parentBlockId === uid
    );
  } else if (type === BLOCK_TYPE_RESOURCE_CENTER_ACTION) {
    titleBlock = blocks.find(
      (b) =>
        b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION_TITLE &&
        b.parentBlockId === uid
    );
  } else if (type === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST) {
    name = 'Checklist';
  }
  if (titleBlock) {
    const [title] = titleBlock.value.split('|-|');
    name = htmlToText(title);
  }
  const icon = getBlockIcon(type);

  const expanded =
    selectedBlockType != null &&
    isBlockOrAncestorOfSelected(blocks, uid, selectedSubItemId);

  const isSelected = uid === selectedSubItemId;
  const childBlocks = blocks.filter((b) => b.parentBlockId === uid);

  // We only reorder child blocks of type ACTION or GROUP (siblings), so:
  const reorderedChildBlocks = childBlocks.sort((a, b) => {
    const indexTypeA = orderedBlockTypes.indexOf(a.type);
    const indexTypeB = orderedBlockTypes.indexOf(b.type);
    if (indexTypeA !== indexTypeB) {
      return indexTypeA - indexTypeB;
    }
    if (
      a.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION &&
      b.type === BLOCK_TYPE_RESOURCE_CENTER_ACTION
    ) {
      const [, indexA] = a.value.split('|-|');
      const [, indexB] = b.value.split('|-|');
      return parseInt(indexA, 10) - parseInt(indexB, 10);
    }
    return 0;
  });

  const isActionRoot = type === BLOCK_TYPE_RESOURCE_CENTER_ACTION;

  const onSelect = (blockType, subItemId) => {
    setSelectedBlockType(blockType);
    let parentId = null;
    const selectedBlock = blocks.find((b) => b.uid === subItemId);

    if (selectedBlock) {
      let currentBlock = selectedBlock;
      while (currentBlock) {
        if (
          [
            BLOCK_TYPE_RESOURCE_CENTER_ACTION,
            BLOCK_TYPE_RESOURCE_CENTER_GROUP,
          ].includes(currentBlock.type)
        ) {
          parentId = currentBlock.uid;
          break;
        }
        const parentIdToFind = currentBlock.parentBlockId;
        currentBlock = blocks.find((b) => b.uid === parentIdToFind);
      }
    }
    setSelectedSubItemId(parentId);
  };

  const onDelete = () => {
    deleteBlockAndChildren(uid);
  };

  return (
    <>
      {/* Top-level NavigatorItem for the current block */}
      <NavigatorItem
        type={type}
        iconClassName={icon}
        iconWrapperClassName="container"
        name={name}
        level={level}
        taskItemId={uid}
        className={classNames(className, 'navigator-block-item', {
          activated: isSelected,
          'top-block': expanded,
        })}
        deletable={type !== BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST}
        isSelected={
          selectedBlockType === type &&
          (uid === selectedSubItemId ||
            type === BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST)
        }
        onSelect={onSelect}
        onDelete={onDelete}
        dragHandleProps={dragHandleProps}
      />

      {type !== BLOCK_TYPE_RESOURCE_CENTER_EMBEDDED_CHECKLIST && (
        <AnimateHeight height={expanded ? 'auto' : 0} duration={300}>
          <div className={classNames('blocks', {expanded})}>
            {/**
             * Here we want a Droppable for the reorderable children that share
             * the same parent (this block). We do not necessarily need to separate
             * them by block type if we want them in the same list. If you do want
             * them separated by type, you'd need multiple droppables.
             */}
            <Droppable
              droppableId={`droppable-${uid}`}
              isDropDisabled={currentDragDroppableId !== `droppable-${uid}`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {reorderedChildBlocks.map((childBlock, index) => {
                    const draggable = [
                      BLOCK_TYPE_RESOURCE_CENTER_ACTION,
                      BLOCK_TYPE_RESOURCE_CENTER_GROUP,
                    ].includes(childBlock.type);

                    if (draggable !== true) {
                      // Render non-draggable leaf nodes directly
                      const {iconClassName, name} =
                        blocksObj.find(
                          (bObj) => bObj.type === childBlock.type
                        ) || {};
                      return (
                        <NavigatorItem
                          key={childBlock.uid}
                          type={childBlock.type}
                          iconClassName={iconClassName}
                          name={name}
                          level={level + 1}
                          taskItemId={childBlock.uid}
                          className={classNames({
                            activated:
                              selectedSubItemId === childBlock.parentBlockId,
                          })}
                          deletable={
                            childBlock.type !==
                            BLOCK_TYPE_RESOURCE_CENTER_GROUP_TITLE
                          }
                          isSelected={
                            selectedBlockType === childBlock.type &&
                            selectedSubItemId === childBlock.parentBlockId
                          }
                          onSelect={(blockType, subId) =>
                            onSelect(blockType, subId)
                          }
                          onDelete={() =>
                            deleteBlockAndChildren(childBlock.uid)
                          }
                        />
                      );
                    }

                    // Render draggable items (ACTION/GROUP)
                    return (
                      <div
                        className={classNames('draggable-wrapper', {
                          selected: selectedSubItemId === childBlock.uid,
                        })}>
                        <Draggable
                          key={childBlock.uid}
                          draggableId={childBlock.uid}
                          index={index}
                          isDragDisabled={!draggable}>
                          {(providedDraggable, snapshot) => (
                            <div
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              className={classNames({
                                'same-droppable-group':
                                  currentDragDroppableId === `droppable-${uid}`,
                              })}>
                              <NavigatorBlockItem
                                block={childBlock}
                                blocks={blocks}
                                level={level + 1}
                                selectedBlockType={selectedBlockType}
                                selectedSubItemId={selectedSubItemId}
                                setSelectedBlockType={setSelectedBlockType}
                                setSelectedSubItemId={setSelectedSubItemId}
                                setMode={setMode}
                                updateStep={updateStep}
                                step={step}
                                evolution={evolution}
                                handleAddActionToGroup={handleAddActionToGroup}
                                deleteBlockAndChildren={deleteBlockAndChildren}
                                className={classNames({
                                  activated:
                                    selectedSubItemId ===
                                    childBlock.parentBlockId,
                                })}
                                dragHandleProps={
                                  providedDraggable.dragHandleProps
                                }
                                currentDragDroppableId={currentDragDroppableId}
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            {/* "Add element" or "Add action" */}
            <div
              className="add-block-wrapper sw-1"
              onClick={() => {
                if (isActionRoot) {
                  setMode(MODE_ADD_BLOCK);
                } else {
                  handleAddActionToGroup(uid);
                }
              }}>
              <div className="add-block-content subtitle-4">
                <i className="icon-plus" />
                {isActionRoot ? 'Add element' : 'Add action'}
              </div>
            </div>
          </div>
        </AnimateHeight>
      )}
    </>
  );
};

export default NavigatorBlockItem;
