import classnames from 'classnames';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import {toastSuccess} from 'components/Toaster';
import copy from 'copy-to-clipboard';
import {useWebhookDelete} from 'hooks/useWebhookDelete';
import {useWebhookUpdateStatus} from 'hooks/useWebhookUpdateStatus';
import {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS,
  ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP,
} from 'router/routes.const';
import {SettingsBody} from 'scenes/Settings/components/Body';
import {webhookService} from 'services';
import {STATUS_LIVE, STATUS_PAUSED} from 'services/webhooks';
import {Swaler} from 'swaler';
import {Subnav} from '../components/Subnav';
import './_styles.scss';
import ModalWebhookDelete from './components/ModalWebhookDelete';

const logger = new Swaler('SettingsWebhook');

const SettingsWebhook = () => {
  const history = useHistory();

  const [webhooks, setWebhooks] = useState([]);
  const [webhookToDelete, setWebhookToDelete] = useState(null);

  const {updateStatus} = useWebhookUpdateStatus({
    onUpdated: (updatedWebhook) => {
      setWebhooks(
        webhooks.map((webhook) =>
          webhook.uid === updatedWebhook.uid ? updatedWebhook : webhook
        )
      );
    },
  });
  const {deleteWebhook, deleting} = useWebhookDelete({
    onDelete: (webhookId) => {
      setWebhooks(webhooks.filter((webhook) => webhook.uid !== webhookId));
      setWebhookToDelete(null);
    },
  });

  const fetchWebhooks = async () => {
    const webhooks = await webhookService.getWebhooks();

    setWebhooks(webhooks);
  };

  const handleCopy = (text) => {
    copy(text);
    toastSuccess('Endpoint url copied to clipboard');
  };

  useEffect(() => {
    fetchWebhooks();
  }, []);

  return (
    <SettingsBody className="s-settings-integrations-webhook">
      <div className="title-3">Integrations</div>
      <Subnav />
      <div className="settings-card card-webhooks">
        <div className="webhooks-header">
          <div className="title-wrapper">
            <div className="subtitle-3">Webhooks</div>
            <div className="body-3 n-700">
              Automatically send any Jimo events data to another tool in
              real-time as the events occur..{' '}
              <a
                href="http://help.usejimo.com"
                target="_blank"
                rel="noopener noreferrer">
                How to set up
              </a>
            </div>
          </div>
          <Button
            iconLeft="icon-plus"
            thin
            onClick={() =>
              history.push(ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP('new'))
            }>
            New Webhook
          </Button>
        </div>
        {webhooks.length > 0 && (
          <div className="webhooks-list">
            {webhooks
              .map((webhook) => ({
                ...webhook,
                events:
                  webhook.events.length > 0 ? webhook.events.split(';') : [],
              }))
              .map((webhook) => {
                return (
                  <Link
                    key={webhook.uid}
                    to={ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_DETAILS(
                      webhook.uid
                    )}
                    className="webhook-item">
                    <div className="icon-wrapper">{webhook.icon}</div>
                    <div className="webhook-name-wrapper">
                      <div className="subtitle-3">{webhook.name}</div>
                      <div className="webhook-details">
                        <div
                          className={classnames('webhook-status', {
                            'is-live': webhook.status === STATUS_LIVE,
                          })}>
                          {webhook.status.toLowerCase()}
                        </div>
                        <div className="webhook-events">
                          <i className="isax isax-mouse-circle"></i>{' '}
                          {webhook.events.length} events
                        </div>
                        <div className="webhook-url">
                          <i className="isax isax-global"></i>
                          <span>{webhook.endpoint}</span>
                        </div>
                      </div>
                    </div>
                    <Dropdown
                      position="bottom right"
                      offsetY={4}
                      trigger={
                        <Button
                          className="btn-menu-webhook"
                          onClick={(e) => {
                            e.preventDefault();
                          }}>
                          <i className="icon-menu-vertical"></i>
                        </Button>
                      }>
                      <div className="dp-menu">
                        <div
                          className="dp-menu-item body-3"
                          onClick={() => handleCopy(webhook.endpoint)}>
                          <i className="isax isax-copy"></i> Copy endpoint URL
                        </div>
                        <div
                          className="dp-menu-item body-3"
                          onClick={() =>
                            updateStatus(
                              webhook.uid,
                              webhook.status === STATUS_LIVE
                                ? STATUS_PAUSED
                                : STATUS_LIVE
                            )
                          }>
                          {webhook.status === STATUS_LIVE ? (
                            <>
                              <i className="isax isax-pause-circle"></i> Pause
                            </>
                          ) : (
                            <>
                              <i className="isax isax-play-circle"></i> Live
                            </>
                          )}
                        </div>
                        <div
                          className="dp-menu-item body-3"
                          onClick={() =>
                            history.push(
                              ROUTE_SETTINGS_INTEGRATIONS_WEBHOOK_SETUP(
                                webhook.uid
                              )
                            )
                          }>
                          <i className="isax isax-edit-2"></i> Edit
                        </div>
                        <div
                          className="dp-menu-item r-400 body-3 danger"
                          onClick={() => setWebhookToDelete(webhook.uid)}>
                          <i className="isax isax-trash"></i>Delete
                        </div>
                      </div>
                    </Dropdown>
                  </Link>
                );
              })}
          </div>
        )}
      </div>
      <ModalWebhookDelete
        isOpen={webhookToDelete != null}
        onConfirm={() => deleteWebhook(webhookToDelete)}
        isConfirming={deleting}
        onRequestClose={() => setWebhookToDelete(null)}
      />
    </SettingsBody>
  );
};

export default SettingsWebhook;
